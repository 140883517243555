import React, { useState } from 'react';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import { ChakraProvider } from '@chakra-ui/react';

import Layout from '../components/Layout/Layout';
import Home from '../pages/private/Home';

import Orders from '../pages/private/orders';
import Settings from '../pages/private/settings';
import OnBoarding from '../pages/private/onboarding';
import QrCode from '../pages/private/QrCode';
import BeatLoader from '../components/common/BeatLoader/BeatLoader';
import MuiSnackbar from '../components/common/Snackbar';
import ThemeProvider from '../theme';
import { theme } from '../chakraTheme/theme';
import { useReduxSelector } from '../store/store';
import { HOME_PAGE, MENU_MANAGER, QR_CODE_PAGE } from './index';
import { DashboardPageIds } from '../constants';
import MobileApp from '../pages/private/mobile-app';
import { getNavLinks } from './nav-config';

const loadableOptions = {
  fallback: <BeatLoader />,
};

const Dashboard = loadable(
  () => import('../pages/private/dashboard'),
  loadableOptions
);
const ActivateAccount = loadable(
  () => import('../pages/private/activate-account'),
  loadableOptions
);
const ListOrders = loadable(
  () => import('../pages/private/orders/ListOrders'),
  loadableOptions
);
const Order = loadable(
  () => import('../pages/private/orders/Order'),
  loadableOptions
);
const Locations = loadable(
  () => import('../pages/private/Locations'),
  loadableOptions
);
const Guests = loadable(
  () => import('../pages/private/Guests'),
  loadableOptions
);

const StoreSettings = loadable(
  () => import('../pages/private/store-settings'),
  loadableOptions
);
const HolidayHours = loadable(
  () => import('../pages/private/holiday-hours'),
  loadableOptions
);
const MenuManager = loadable(
  () => import('../pages/private/MenuManager'),
  loadableOptions
);
const ListDiscount = loadable(
  () => import('../pages/private/discounts/list-discount'),
  loadableOptions
);
const CreateDiscount = loadable(
  () => import('../pages/private/discounts/create-discount'),
  loadableOptions
);
const EditDiscount = loadable(
  () => import('../pages/private/discounts/edit-discount'),
  loadableOptions
);
const Statements = loadable(
  () => import('../pages/private/statements'),
  loadableOptions
);
const Team = loadable(() => import('../pages/private/team'), loadableOptions);

// Integrations
const AllIntegrations = loadable(
  () => import('../pages/private/integrations/AllIntegrations'),
  loadableOptions
);
const Square = loadable(
  () => import('../pages/private/integrations/square'),
  loadableOptions
);

const Clover = loadable(
  () => import('../pages/private/integrations/clover'),
  loadableOptions
);

const OtterXClover = loadable(
  () => import('../pages/private/integrations/otterxclover'),
  loadableOptions
);

const Doordash = loadable(
  () => import('../pages/private/integrations/doordash'),
  loadableOptions
);

const DoordashSettings = loadable(
  () => import('../pages/private/integrations/doordash-settings'),
  loadableOptions
);

const MetaPixel = loadable(
  () => import('../pages/private/integrations/meta-pixel'),
  loadableOptions
);

const GoogleAnalytics = loadable(
  () => import('../pages/private/integrations/google-analytics'),
  loadableOptions
);

const OtterxToast = loadable(
  () => import('../pages/private/integrations/otterxtoast'),
  loadableOptions
);

const WebWidget = loadable(
  () => import('../pages/private/web-widget'),
  loadableOptions
);
const ListQrCode = loadable(
  () => import('../pages/private/QrCode/list-qrcode'),
  loadableOptions
);
const DesignQr = loadable(
  () => import('../pages/private/QrCode/DesignQr'),
  loadableOptions
);
const CreateQr = loadable(
  () => import('../pages/private/QrCode/CreateQr'),
  loadableOptions
);
// SETTINGS
const BankAccount = loadable(
  () => import('../pages/private/settings/bank-account'),
  loadableOptions
);
const Account = loadable(
  () => import('../pages/private/settings/account'),
  loadableOptions
);

const UpgradePlan = loadable(
  () => import('../pages/private/settings/upgrade-plan'),
  loadableOptions
);

const MUI_PAGES = [
  MENU_MANAGER,
  `${QR_CODE_PAGE}/create`,
  `${QR_CODE_PAGE}/design`,
];

const isPageAllowed = (
  pagesIds: DashboardPageIds[],
  pageId: DashboardPageIds
) => pagesIds.includes(pageId);

function Private() {
  // TODO -- will move it to component trial banner component once removed MUI completely
  const [isOpen, setOpen] = useState(true);
  const { user, temporarilyFinishOnboarding } = useReduxSelector(
    (state) => state.auth
  );
  const location = useLocation();
  const { pathname } = location;

  const { allowedPages } = React.useMemo(
    () => getNavLinks(user, temporarilyFinishOnboarding),
    [user, temporarilyFinishOnboarding]
  );
  const isMuiPage = React.useMemo(
    () => MUI_PAGES.find((muiPage) => pathname.includes(muiPage)),
    [pathname]
  );

  if (user.onboardingInProgress && !temporarilyFinishOnboarding) {
    return (
      <ChakraProvider theme={theme}>
        <OnBoarding />
      </ChakraProvider>
    );
  }

  const notFound = <Route path="*" element={<Navigate to={HOME_PAGE} />} />;

  if (isMuiPage) {
    return (
      <ThemeProvider>
        <MuiSnackbar />

        <Routes>
          <Route
            path="/"
            element={<Layout isOpen={isOpen} setOpen={setOpen} />}
          >
            <Route index element={<Home />} />
            <Route path="menu-manager" element={<MenuManager />} />

            <Route path="qr-code" element={<QrCode />}>
              <Route path="create" element={<CreateQr />} />
              <Route path="design/:qrId" element={<DesignQr />} />
            </Route>

            {notFound}
          </Route>
        </Routes>
      </ThemeProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Layout isOpen={isOpen} setOpen={setOpen} />}>
          <Route index element={<Home />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="activate-account" element={<ActivateAccount />} />
          <Route path="store-settings" element={<StoreSettings />} />
          <Route path="web-widget" element={<WebWidget />} />
          <Route path="mobile-app" element={<MobileApp />} />

          {isPageAllowed(allowedPages, DashboardPageIds.ORDERS) && (
            <Route path="orders" element={<Orders />}>
              <Route index element={<ListOrders />} />
              <Route path=":orderId" element={<Order />} />
            </Route>
          )}

          {isPageAllowed(allowedPages, DashboardPageIds.LOCATIONS) && (
            <Route path="locations" element={<Locations />} />
          )}

          {isPageAllowed(allowedPages, DashboardPageIds.CUSTOMER) && (
            <Route path="guests" element={<Guests />} />
          )}
          {isPageAllowed(allowedPages, DashboardPageIds.HOLIDAY_HOURS) && (
            <Route path="holiday-hours" element={<HolidayHours />} />
          )}

          {isPageAllowed(allowedPages, DashboardPageIds.DISCOUNTS) && (
            <>
              <Route path="discounts" element={<ListDiscount />} />
              <Route path="discounts/create" element={<CreateDiscount />} />
              <Route path="discounts/:discountId" element={<EditDiscount />} />
            </>
          )}

          {isPageAllowed(allowedPages, DashboardPageIds.STATEMENTS) && (
            <Route path="statements" element={<Statements />} />
          )}
          {isPageAllowed(allowedPages, DashboardPageIds.TEAM) && (
            <Route path="team" element={<Team />} />
          )}
          {isPageAllowed(allowedPages, DashboardPageIds.INTEGRATIONS) && (
            <>
              <Route path="integrations" element={<AllIntegrations />} />
              <Route path="integrations/square" element={<Square />} />
              <Route path="integrations/clover" element={<Clover />} />
              <Route
                path="integrations/otterxclover"
                element={<OtterXClover />}
              />
              <Route
                path="integrations/otterxtoast"
                element={<OtterxToast />}
              />
              <Route path="integrations/doordash" element={<Doordash />} />
              <Route
                path="integrations/doordash/settings"
                element={<DoordashSettings />}
              />
              <Route path="integrations/metaPixel" element={<MetaPixel />} />
              <Route
                path="integrations/googleAnalytics"
                element={<GoogleAnalytics />}
              />
            </>
          )}

          {/* Design QR Page */}
          <Route path="qr-code" element={<ListQrCode />} />
          {notFound}
        </Route>

        <Route path="settings" element={<Settings />}>
          <Route index element={<Account />} />
          <Route path="upgrade-plan" element={<UpgradePlan />} />
          <Route path="bank-account" element={<BankAccount />} />
          {notFound}
        </Route>
      </Routes>
    </ChakraProvider>
  );
}

export default Private;
